<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Manhours Spending
      </b-card-title>
      <!-- <feather-icon
        icon="SettingsIcon"
        size="18"
        class="text-muted cursor-pointer"
      /> -->
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3">
        <div class="mr-2">
          <b-card-text class="mb-50">
            Time Planned
          </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">hrs </sup>
            <span class=" ">{{ revenue.timeplanned }}</span>
          </h3>
        </div>
        <div>
          <b-card-text class="mb-50">
            Actual Hours
          </b-card-text>
          <h3 class="font-weight-bolder">
            <sup class="font-medium-1">hrs </sup>
            <span class="text-primary">{{ revenue.actualhours }}</span>
          </h3>
        </div>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        type="line"
        height="240"
        :options="revenueComparisonLine.chartOptions"
        :series="revenueComparisonLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      revenue: {},
      revenueComparisonLine: {
        series: [
          {
            name: 'Time Planned',
            data: [0, 10, 15, 20, 30, 35, 40, 44, 48, 50, 55, 60],
            // data: [0, 10, 15, 20, 30, 32, 34, 36, 38, 40, 44, 48, 50, 55, 60],
          },
          {
            name: 'Actual Hours',
            data: [0, 18, 28, 35, 40, 55, 65, 70, 75, 80, 90, 100],
            // data: [0, 18, 28, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 90, 100],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'straight',
            // dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: ['#28C76F', '#ebe9f1'],
          fill: {
            type: 'solid',
            // gradient: {
            //   shade: 'dark',
            //   inverseColors: false,
            //   gradientToColors: [$themeColors.primary, '#ebe9f1'],
            //   shadeIntensity: 1,
            //   type: 'horizontal',
            //   opacityFrom: 1,
            //   opacityTo: 1,
            //   stops: [0, 100, 100, 100],
            // },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            // categories: ['27/08', '05/09', '14/09', '23/09', '02/10', '11/10', '20/10', '20/10', '07/11', '16/11', '25/11', '4/12', '13/12', '22/12', '31/12'],
            categories: ['June ‘19', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec', 'Jan ‘20', 'Feb', 'Mar', 'Apr', 'May'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            max: 100,
            min: 0,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                // return val > 999 ? `${(val / 1000).toFixed(0)}k` : val%
                return `${val}%`
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/revenue').then(res => { this.revenue = res.data })
  },
}
</script>
