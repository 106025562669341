<template>
  <b-card>
    <b-row class="pb-50">

      <!-- text and button -->
      <b-col
        lg="6"
        order-lg="1"
        order="2"
        class="d-flex justify-content-between flex-column"
      >
        <div class="mt-1 mt-lg-0">
          <h2 class="font-weight-bolder mb-25">
            <!-- {{ kFormatter(budget.total) }} -->
            <!-- RM{{ toMillion(costBudget) }} -->
            RM 180 Million
          </h2>
          <b-card-text class="font-weight-bold mb-2">
            {{ budget.description }}
          </b-card-text>

          <!-- <div class="font-medium-2 mb-1 mb-lg-0">
            <span class="text-success mr-50">{{ budget.target }}</span>
            <span>on target achievement</span>
          </div> -->
        </div>

        <!-- <div class="button">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="shadow"
          >
            View Details
          </b-button>
        </div> -->
      </b-col>

      <!-- dropdown and chart -->
      <b-col
        lg="6"
        cols="12"
        order-lg="2"
        order="1"
        class="d-flex justify-content-between flex-column text-right"
      >
        <div>
          <b-dropdown
            text="Last 12 Months"
            variant="transparent"
            left
            size="sm"
          >
            <b-dropdown-item
              v-for="day in budget.lastDays"
              :key="day"
            >
              {{ day }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <!-- apex chart -->
        <vue-apex-charts
          type="bar"
          height="200"
          :options="salesBar.chartOptions"
          :series="salesBar.series"
        />
      </b-col>
    </b-row>
    <hr>

    <!-- progress bar -->
    <b-row class="avg-sessions pt-50">
      <!-- <b-col
        cols="6"
        class="mb-2"
      >
        <b-card-text class="mb-50">
          Over Budget: ${{ budget.overBudget }}
        </b-card-text>
        <b-progress
          value="50"
          max="100"
          height="6px"
          variant="danger"
        />
      </b-col> -->
      <!-- <b-col
        cols="6"
        class="mb-2"
      >
        <b-card-text class="mb-50">
          Under Budget: {{ kFormatter(budget.underBudget) }}
        </b-card-text>
        <b-progress
          value="60"
          max="100"
          height="6px"
          variant="warning"
        />
      </b-col> -->
      <b-col cols="6">
        <b-card-text class="mb-50">
          <!-- Total Spending: RM{{ toMillion(totalSpending) }} -->
          Total Spending: RM75 Million
        </b-card-text>
        <b-progress
          :value="totalSpending"
          :max="totalMaxSpending"
          height="6px"
          variant="info"
          class="mt-25"
        />
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50">
          <!-- Total Duration: {{ budget.duration }} -->
          Total Duration: 12 months
        </b-card-text>
        <b-progress
          value="70"
          max="100"
          variant="success"
          height="6px"
          class="mt-25"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BProgress, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
// import { $themeColors } from '@themeConfig'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    // BButton,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      budget: {},
      salesBar: {
        series: [
          {
            name: 'Months',
            data: [3.0, 7.2, 7.1, 7.7, 10.2, 3.3, 6.5, 8.3, 6.9, 5.5, 4.4, 4.9],
            // data: [3000000, 7200000, 7100000, 7700000, 1020000, 3300000, 6500000, 8300000, 6900000, 5500000, 4400000, 4900000],
            // data: [5, 5, 6, 7, 8, 4, 6, 5, 4],
            // data: [5, 5, 6, 7],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: 'none',
            },
          },
          colors: [
            '#ebf0f7',
            '#ebf0f7',
            // $themeColors.primary,
            '#7367F0',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
            '#ebf0f7',
          ],
          plotOptions: {
            bar: {
              columnWidth: '45%',
              distributed: true,
              borderRadius: 23,
              color: '#7367F0',
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: 'numeric',
          },
          yaxis: {
            labels: {
              style: {
                colors: "#b9b9c3",
                fontSize: "1rem",
              },
              formatter(val) {
                return `${val}M`
              },
            },
          },

          // xaxis: {
          //   categories: ["Jan", "Feb", "Mar", "Dec"],
          // },
        },
      },
      totalSpending: 0,
      totalMaxSpending: 0,
      costBudget: 0,
    }
  },
  created() {
    this.$http.get('/analytics/data').then(res => { this.budget = res.data.budget })
    this.$axios.get(`${this.$baseUrl}/Dashboard/getTotalSpending`).then(res => {
      console.log(res)
      // this.totalSpending = res.data.data.spending
      // this.totalMaxSpending = res.data.data.total
      this.totalSpending = res.data.data.total
      this.totalMaxSpending = res.data.data.spending
    })
    this.$axios.get(`${this.$baseUrl}/Dashboard/getBudget`).then(res => {
      console.log(res)
      this.costBudget = res.data.budget.total_budget
    })
  },
  methods: {
    kFormatter,
    toMillion(labelValue) {
    // Nine Zeroes for Billions
      // eslint-disable-next-line no-nested-ternary
      return Math.abs(Number(labelValue)) >= 1.0e+9

        ? `${Math.abs(Number(labelValue)) / 1.0e+9} Billion`
        // eslint-disable-next-line no-nested-ternary
        : Math.abs(Number(labelValue)) >= 1.0e+6

          ? `${Math.abs(Number(labelValue)) / 1.0e+6} Million`
        // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3

            ? `${Math.abs(Number(labelValue)) / 1.0e+3} Thousands`

            : Math.abs(Number(labelValue))
    },
  },
}
</script>
