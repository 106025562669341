<template>
  <b-card
    v-if="data"
    class="text-center"
    no-body
  >
    <b-card-header>
      <span
        style="font-size: 14px"
        class="mb-0"
      >
        {{ data.name }} Overview
      </span>
      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- tambah v-if data.name = safety -->
    <div v-if="data.name !== 'Safety'">
      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="200"
        class="my-2 mx-auto"
        :options="goalOverviewRadialBar"
        :series="data.series"
      />
      <!-- {{ data.series }} -->
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Total
          </b-card-text>
          <span class="font-weight-bolder mb-0">
            {{ data.planning }}
          </span>
        </b-col>

        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            <div v-if="data.name === 'Quality'">
              Resolve
            </div>
            <div v-else>
              Actual
            </div>

          </b-card-text>
          <span class="font-weight-bolder mb-0">
            {{ data.actual }}
          </span>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div style="padding-top: 25%;">
        <p style="font-weight: 600; font-size: 2.2rem;">
          5
        </p>
        <p style="font-weight: 600; font-size: 2.2rem; color: #B9B9C3;">
          Incidents
        </p>
      </div>
      <!-- <vue-apex-charts
        type="radialBar"
        height="200"
        class="my-2 mx-auto"
        :options="goalOverviewRadialBar"
        :series="data.series"
      /> -->
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

const $strokeColor = '#BABFC7'
const $textHeadingColor = '#5e5873'
// const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [this.data.color],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
                // fontSize: "30px",
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.2rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [this.data.color],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
}
</script>
