<template>
  <b-card>
    <b-card-title class="mb-2">
      {{ issues.title }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ issues.description }}
    </b-card-sub-title>

    <vue-apex-charts
      ref="complianceChart"
      type="donut"
      height="350"
      :series="issuesChartData.series"
      :options="issuesChartData.chartOptions"
    />
    <!-- :options="issuesChartData.donutChart.chartOptions"
      :series="issuesChartData.donutChart.series" -->
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      issues: {
        title: 'Safety Issues',
        description: 'Total Safety Issue',
      },
      totalCases: 0,
      issuesChartData: {
        series: [1, 1, 1, 1, 1],
        // series: [72, 14, 28, 36],
        // series: [0, 0, 0, 0],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#FFE700', '#00cfe8', '#FF9B9E', '#8769FF', '#00D4BD'],
          dataLabels: {
            enabled: true,
            formatter(val) {
              console.log(val)
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      // return `${parseInt(val)}%`
                      return val
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1rem',
                    label: 'Total',
                    // formatter(val) {
                    //   return val
                    // },
                  },
                },
              },
            },
          },
          labels: ["Structure/object misplacement", "Improper weld", "Mismeasurement", "Low-quality concrete mix", "Mismeasurement"],
          // labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

    }
  },
  created() {
  //   this.issues = {
  //     title: 'Safety Issues',
  //     description: 'Total Safety Issue',
  //   }
  //   this.$axios.get(`${this.$baseUrl}/Dashboard/getNonComplianceIssues`).then(res => {
  //     // console.log(res)
  //     // this.overview.type[1].series[0] = Math.round(res.data.data.percentage * 100) / 100
  //     const seriesArray = []
  //     const colorsArray = []
  //     const labelsArray = []
  //     let totalCasesAPI = 0
  //     const allIssues = res.data.data.byTypesData

  //     allIssues.forEach((issue, index) => {
  //       // const randomColor = Math.floor(Math.random() * 16777215).toString(16)
  //       const randomColor = allIssues.length < 8 ? [
  //         '#FFE700', '#00D4BD', '#8769FF',
  //         '#ff3333', '#EA5455', '#FF9F43', '#00AE9D', '#004ED5',
  //       ] : Math.floor(Math.random() * 16777215).toString(16)
  //       // colorsArray.push(`#${randomColor}`)
  //       colorsArray.push(allIssues.length < 8 ? randomColor[index] : `#${randomColor}`)
  //       seriesArray.push(issue.item)
  //       labelsArray.push(issue.label)
  //       totalCasesAPI += issue.item
  //     })
  //     this.issuesChartData = {
  //       ...this.issuesChartData,
  //       ...{
  //         series: seriesArray,
  //         chartOptions: {
  //           colors: colorsArray,
  //           labels: labelsArray,
  //         },
  //       },
  //     }
  //     this.totalCases = totalCasesAPI
  //     window.dispatchEvent(new Event('resize'))
  //   })
  },
}
</script>
