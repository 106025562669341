<template>
  <b-card
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Weekly Task
        </b-card-title>
        <b-card-sub-title
          class="mb-2 text-muted"
        >
          Percentage workload in a week
        </b-card-sub-title>
      </div>

      <!-- <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <!-- body -->
    <b-card-body style="overflow: auto; height: 400px;">
      <div
        v-for="(list, index) in weeklyTasks"
        :key="index"
        class="d-flex justify-content-between align-items-center p-1"
      >
        <div class="d-flex align-items-center">
          <div class="mr-1">
            <file-text-icon
              size="1.5x"
              class="custom-class"
            />
          </div>
          <div>
            <span style="font-size: 14px; font-weight: 500; font-size: 14.5242px; line-height: 24px;">
              {{ list.taskName }}
            </span>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <!-- <span class="font-weight-bold text-body-heading mr-1">{{
            list.name
          }}</span> -->
          <span
            class="mr-2"
            style="font-weight: 500; font-size: 14.5242px; line-height: 24px;"
          > {{ chartData[index].series[0] }}%</span>
          <vue-apex-charts
            type="radialBar"
            height="30"
            width="30"
            :options="chartData[index].options"
            :series="chartData[index].series"
          />
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  // BCardText,
  BCardBody,
  // BDropdown,
  // BDropdownItem,
  BCardSubTitle,
} from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"
import { FileTextIcon } from 'vue-feather-icons'
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef"
export default {
  components: {
    FileTextIcon,
    BCard,
    BCardHeader,
    BCardTitle,
    // BCardText,
    BCardBody,
    // BImg,
    // BDropdown,
    // BDropdownItem,
    VueApexCharts,
    BCardSubTitle,
  },
  data() {
    // console.log(this.wee)
    return {
      chartData: [],
      weeklyTasks: [],
      chartClone: {},
      chartColor: [
        $themeColors.primary,
        $themeColors.warning,
        $themeColors.secondary,
        $themeColors.info,
        $themeColors.danger,
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: "22%",
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: "always",
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: "round",
          },
        },
      },
    }
  },
  created() {
    // this.$axios.get(`${this.$baseUrl}/Dashboard/getWeeklyTask`).then(res => {
    //   this.weeklyTasks = res.data.weeklyTask
    //   this.weeklyTasks.forEach((task, index) => {
    //     console.log(task)
    //     // const randomColor = Math.floor(Math.random() * 16777215).toString(16)
    //     const randomColor = this.weeklyTasks.length < 8 ? [
    //       '#FFE700', '#00D4BD', '#8769FF',
    //       '#ff3333', '#EA5455', '#FF9F43', '#00AE9D', '#004ED5',
    //     ] : Math.floor(Math.random() * 16777215).toString(16)

    //     const chartClone = JSON.parse(JSON.stringify(this.chart))
    //     chartClone.options.colors[0] = this.weeklyTasks.length < 8 ? randomColor[index] : `#${randomColor}`
    //     // eslint-disable-next-line prefer-destructuring
    //     chartClone.series[0] = task.percentage
    //     this.chartData.push(chartClone)
    //     // window.dispatchEvent(new Event('resize'))
    //     window.dispatchEvent(new Event('resize'))
    //   })
    // })
    this.$http.get("/analytics/data").then(response => {
      const dashboardData = response.data
      this.weeklyTasks = dashboardData.weeklyOverview.listTasks
      for (let i = 0; i < this.weeklyTasks.length; i += 1) {
        const chartClone = JSON.parse(JSON.stringify(this.chart))
        chartClone.options.colors[0] = this.weeklyTasks[i].color
        // eslint-disable-next-line prefer-destructuring
        chartClone.series[0] = this.weeklyTasks[i].series[0]
        this.chartData.push(chartClone)
      }
    })
  },
}
</script>
